import styled from 'styled-components';
import { TabList} from 'react-tabs';
import {device} from '../../../components/device';

export const GallerySection = styled.section`
    padding:100px 0px 70px;
    border-bottom:1px solid #ccc;
    @media ${device.tablet} {
        padding:80px 0px 50px;
    }
`;

export const CustomTabList = styled(TabList)`
    margin-bottom: 30px;
    text-align: center;
    margin-left:0px;

    .react-tabs__tab{
        border:none;
        background:#f7f7f7;
        margin:0px 10px;
        padding:8px 15px;
        font-size:16px;
        margin-bottom:10px;
    }

    .react-tabs__tab--selected{
        background:#2560d4;
        border:none;
        border-radius:0px;
        color:#fff;
    }
`;
export const ImageHolder = styled.div`
    margin-bottom:30px;
    cursor:pointer;

    .GalleryImage{
        height:260px;
    }
`;