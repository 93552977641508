import React,{Component} from "react";
import Banner from "../containers/CommonBanner";
import Headermenu from "../containers/HeaderMenuBS";
import Footer from "../containers/Footer";
import Gallery from "../containers/GalleryPage/GalleryPage";
import GlobalStyle from "../components/global-styles";
import "../components/layout.css";
import SEO from "../components/seo";
import BackgroundImg from '../assets/gallery-images/banner.jpg';
import Fonts from "../components/fonts";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class GallerySection extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpenLightBox: false,
            lightBoxImages:[],
            photoIndex: 0,
        }
        this.openLightBox = this.openLightBox.bind(this);
    }

    openLightBox(imageArray,idx)
    {
        const images = [];
        imageArray.forEach(element => { 
            images.push(element.GalleryImg.childImageSharp.fluid.src);
        }); 
        this.setState(
            {
                isOpenLightBox: true,
                lightBoxImages:images,
                photoIndex:idx
            }
        );
    }

    render() {
        return(
            <div>
                {
                    <div>
                        <Fonts/>
                        <GlobalStyle />
                        <main>
                            <SEO 
                                title="Gallery | Pool Website Template | GatsbyJS Templates | Garnet"
                                description="Clean and professional React GatsbyJS Pool Website template from Garnet"
                            />
                            <Headermenu />
                            <Banner
                                BackgroundImg={BackgroundImg}
                                pageTitle="Gallery"
                                breadCrumbsFirst="Home"
                                breadCrumbsSecond="Gallery"
                                breadCrumbsFirstLink="/"
                                breadCrumbsSecondLink="/gallery"
                            />
                            <Gallery 
                                openLightBox = {this.openLightBox}
                            />
                        </main>
                        <Footer />
                    </div>
                }
                {
                    this.state.isOpenLightBox && (
                    <Lightbox
                        mainSrc={this.state.lightBoxImages[this.state.photoIndex]}
                        nextSrc={this.state.lightBoxImages[(this.state.photoIndex + 1) % this.state.lightBoxImages.length]}
                        prevSrc={this.state.lightBoxImages[(this.state.photoIndex + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length]}
                        onCloseRequest={() => this.setState({ isOpenLightBox: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.state.lightBoxImages.length,
                        })
                        }
                    />
                    )
                }
            </div>
        );
    }
}

export default GallerySection;
