import React,{Component} from 'react';
import {GallerySection,CustomTabList,ImageHolder} from './gallerypage.style';
import {Row,Col,Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

class GalleryPage extends Component{

    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }
    render(){
        return (
            <div>
            {
                <GallerySection id="GalleryContainer">
                    <Container>
                        <Tabs>
                            <CustomTabList>
                            {
                                this.props.galleryJson.Gallery.TabList.map((item,idx) => {
                                    return <Tab>{item.Tab}</Tab>
                                })
                            }
                            </CustomTabList>
                            {
                                this.props.galleryJson.Gallery.TabContent.map((itemTab,idxTab) => {
                                return <TabPanel>
                                            <Row>
                                            {
                                                itemTab.Tab.Row.map((item,idx) => {
                                                return <Col md={4} onClick={this.openDialogBox.bind(this,itemTab.Tab.Row,idx)}>
                                                    <ImageHolder>
                                                        <GatsImg 
                                                            fluid={item.GalleryImg.childImageSharp.fluid} 
                                                            alt=""
                                                            className="GalleryImage"
                                                        />
                                                    </ImageHolder>
                                                </Col>
                                                })
                                            }
                                            </Row>
                                    </TabPanel>
                                })
                            }
                        </Tabs>
                    </Container>
                </GallerySection>
            }
            </div>
        )
    }
}

export default props => (
<StaticQuery
    query={graphql`
        query {
            galleryJson{
                Gallery{
                    TabList{
                        Tab
                    }
                    TabContent{
                        Tab{
                            Row{
                                GalleryImg{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    `}
    render={(data) => (
        <GalleryPage 
        galleryJson={data.galleryJson} 
        {...props}
        />
    )}
/>
)